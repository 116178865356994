@use 'sass:math';

// Legacy - delete
$desktop-max-width: -1px;

$phone-basis-width: 320px;
$phone-width: 640px; // Phone STOPS at 640px while expanding
$phone-landscape-width: 731px;

$desktop-basis-width: 1440px;
$desktop-width: 1920px; // desktop STOPS at 1920px while expanding

$desktop-1920-basis-width: 1920px;
$desktop-landscape-width: $desktop-max-width; // desktop-1920 STARTS at 1920px while expanding

@mixin convertToUnit($properties, $basis-width) {
  @each $property, $values in $properties {
    $newValues: ();

    @each $value in $values {
      @if type-of($value) == 'number' and unit($value) == 'px' {
        $newValues: append($newValues, (math.div($value, $basis-width)) * 100vw);
      } @else {
        $newValues: append($newValues, $value);
      }
    }

    #{$property}: $newValues;
  }
}

@mixin desktop-1920($properties: ()) {
  @include convertToUnit($properties, $desktop-1920-basis-width);
  @content;
}

@mixin desktop($properties: ()) {
  @include convertToUnit($properties, $desktop-basis-width);
  @content;
}

@mixin phone($properties: ()) {
  @media (max-width: $phone-width) {
    @include convertToUnit($properties, $phone-basis-width);
    @content;
  }
}
