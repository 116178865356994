.nmx-client-login {
  position: absolute;

  // default modal styles // TODO: move to global default styles
  .nmx-modal {
    position: absolute;
    z-index: $z-index-620-modal-main;
    display: none;
    width: 100%;
    max-width: rem-calc(800px);
    height: 100%;
    padding: 0;
    border: 0;
    margin: 0 auto;
    backface-visibility: hidden;
    background-color: $color-neutral-white;
    box-shadow: none;
    overflow-y: scroll;

    // TODO: this section needs rework
    @include breakpoint(medium) {
      width: 90%; // spec: medium=8cols, large=6cols
      height: auto;
      min-height: rem-calc(300px);
      border-radius: 4px;
      overflow-y: auto;
      @include shadow(large);
    }

    @include breakpoint(large) {
      width: 75%;
    }
    @include breakpoint(xlarge) {
      width: 40%;
      max-width: rem-calc(900px);
    }

    // modal overlay // TODO: move to global default styles
    &__overlay {
      position: fixed;
      z-index: $z-index-610-modal-overlay;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: none;
      background-color: rgba($color-neutral-black, 0.5);
      overflow-y: scroll;
    }

    .nmx-row {
      @include breakpoint(medium) {
        flex-direction: row-reverse;
      }
    }

    // modal content
    .nmx-client-login-modal {
      &__content {
        padding: 1rem;
        text-align: center;
        @include breakpoint(medium) {
          padding: 3rem;
        }
        h4 {
          font-weight: $weight-medium !important; // TODO: not cool, but getting this out the door... come back and revisit why other methods didn't work
        }
      }
      &__close-button {
        position: absolute;
        top: 0;
        right: 0;
        width: rem-calc(42px);
        min-width: unset;
        padding: rem-calc(12px);
        border: none;
        background: transparent
          url('/template/assets/<%=templateVersion%>/images/icons/utility/close.svg')
          no-repeat 50% 50%; // TODO: encode svg or convert to CSS
        background-size: rem-calc(16px);
        float: right;
        text-indent: rem-calc(-9999px);
        @include breakpoint(medium) {
          left: 90%;
        }
      }

      &__fieldset {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &__inline-notification {
        display: none;
        align-items: center;
        padding: 1rem;
        border-left: 5px solid $color-system-negative-error;
        margin-bottom: 1rem;
        background-color: $color-neutral-gray-99;

        &.is-active {
          display: flex;
        }
        &-text {
          max-width: 100%;
          flex: 1 0 auto;
          font-size: 1rem;
        }
        .nmx-icon {
          .icon-fill {
            fill: $color-system-negative-error;
          }
          .icon-color {
            fill: $color-neutral-white;
          }
        }
      }

      &__form {
        &-field {
          display: block;
          width: 100%;
          height: rem-calc(60px);
          padding: rem-calc(10px);
          margin-bottom: 1rem;
          font-size: rem-calc(24px);
          line-height: rem-calc(36px);
          &-label {
            display: block;
            margin-bottom: rem-calc(4px);
            font-weight: $weight-regular;
            text-align: left;
          }
        }
      }
      &__login-assistance {
        margin-bottom: 1.5rem;
      }
      &__submit {
        margin-bottom: 1rem;
      }
      &__other-accounts {
        padding-top: 1.5rem;
        .link-list {
          display: inline-block;
          &__item {
            display: block;
            @include breakpoint(medium) {
              display: inline-block;
            }
            margin: rem-calc(8px);
            &:not(:last-child) {
              padding-right: rem-calc(16px);
              @include breakpoint(medium) {
                border-right: 1px solid $color-neutral-gray-59;
              }
            }
          }
        }
      }
      &__info {
        display: none;
        padding: 1rem;
        background-color: $color-neutral-gray-95;
        text-align: center;
        &.is-active {
          display: block;
        }
        @include breakpoint(medium) {
          padding: 3rem;
        }
        .nmx-icon {
          display: none;
          @include breakpoint(medium) {
            display: inline-block;
          }
        }
        h5 {
          padding-bottom: 1rem;
          font-weight: $weight-regular;
        }
        .benefits-list {
          margin: 1rem 10%;
          text-align: left;
          @include breakpoint(medium) {
            margin: 2rem 0;
          }
          &__item {
            padding-left: 1rem;
            font-weight: $weight-light;
            &:not(:last-child) {
              padding-bottom: rem-calc(12px);
            }
          }
        }
        &-close-button {
          border: none;
          background-color: unset;
          cursor: pointer;
        }
      }
      &__app-download-container {
        padding: rem-calc(24px) 0 rem-calc(16);
        text-align: center;
        background-color: $color-neutral-gray-99;
      }
      &__button-hide-show-password {
        position: absolute;
        right: rem-calc(50px);
        margin-top: rem-calc(150px);
        @include breakpoint(medium) {
          right: rem-calc(80px);
        }
        @include breakpoint(xlarge) {
          margin-top: rem-calc(163px);
        }
        background: none;
        border: none;
        .nmx-icon {
          .icon-fill {
            fill: $color-system-cta-blue;
          }
        }
      }
      &__app_download-heading {
        padding-bottom: rem-calc(16);
      }
      &__download-link {
        display: inline-block;
        vertical-align: top;
        .nmx-icon.badge-appStore {
          max-height: rem-calc(40px);
        }
        .nmx-icon.badge-googlePlay {
          max-height: rem-calc(60px);
          margin-top: rem-calc(-10px);
        }
      }
    }
  }

  // Active state
  &.is-active {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .nmx-modal {
      display: block;
      @media all and (-ms-high-contrast: none) {
        top: rem-calc(
          50px
        ); // ie11 hack to statically-position non-behaving modal
        left: 25%;
      }
    }

    .nmx-modal__overlay {
      display: flex;
      align-items: center;
      margin: auto;
    }
  }
}
