@use 'sass:math';

// @import 'settings';

.nmx-row {
  display: flex;
  flex-wrap: wrap;

  &--reverse {
    flex-direction: row-reverse;
  }

  &--align-center {
    justify-content: center;
  }

  &--align-left {
    align-self: flex-start;
  }

  &--align-right {
    justify-content: flex-end;
  }

  .nmx-row {
    // TODO: revisit this in conjunction with the nmx-col-nested classes
    margin: 0;
  }

  // TODO: revisit nested cols... maybe a .nmx-row.no-padding class as a parent for this?
  // & > [class^="nmx-col"], // TODO: retest this
  // & > [class*="nmx-col"] {
  //   padding-right: 0;
  //   padding-left: 0;
  // }
}

.nmx-container {
  width: 100%;
  max-width: $grid-container-width;
  flex: 0 0 auto; // ie fix
  margin: auto;
  &.nmx-container-home {
    max-width: rem-calc(
      1440px
    ); // TODO: old grid max-width (home page campaign): rework /home page into 1200px-wide content container
  }
}

.nmx-col {
  @include col;

  // center column padding
  // TODO: rip these out if not needed, and follow proper grid framework
  // &.nmx-col-left {
  //   @include breakpoint(medium) {
  //     padding: 0 1rem 0 0.25rem;
  //   }
  // }
  // &.nmx-col-right {
  //   @include breakpoint(medium) {
  //     padding: 0 0.25rem 0 1rem;
  //   }
  // }

  // TODO: confirm this nested class is what we want to do. &.nmx-col-mobile-content vs .nmx-col-mobile-content
  &.nmx-col-mobile-content {
    // TODO: assimilate (or kill this off based on nmx-col-nested > nmx-col-mobile-padding (below))
    @include breakpoint($max: medium) {
      padding-right: $grid-mobile-content-padding;
      padding-left: $grid-mobile-content-padding;
    }
  }

  &.nmx-col-nested {
    max-width: none;
    margin: 0 auto;

    // used when inner content needs to stretch 100% viewport (on mobile) // use on top-most col container
    // max-width: rem-calc(1200px);
    // padding-top: rem-calc(16px);
    // margin: 0 auto;
    @include breakpoint($max: medium) {
      padding-right: 0;
      padding-left: 0;
    }
    @include breakpoint(medium) {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    }

    // @include breakpoint(xlarge) {
    //   margin-right: 0.75rem; // TODO: ensure removing these doesn't break /home and /american-dream pages
    //   margin-left: 0.75rem; // TODO: ensure removing these doesn't break /home and /american-dream pages
    // }

    // this can be left in the CSS, unreplicated in the styled component for now.
    // if we can pass in this class if we need these styles
    .nmx-col-mobile-padding {
      // used on inner content to match default mobile grid outer gutters
      @include breakpoint($max: medium) {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }

    .nmx-col-nested-child-container {
      width: 100%;
      max-width: 75rem; // 1200px
      margin: 0 auto;
    }

    &-child {
      // simulate total default base grid column padding on nested inner columns
      padding: 0 1rem;
      // large up
      @include breakpoint(large) {
        padding: rem-calc(0 20px);
      }
      // xlarge up --> need to make sure I convert this properly to the theme
      @include breakpoint(xlarge) {
        padding: rem-calc(0 32px);
      }
      // @include breakpoint(xxlarge) {
      //   padding: 0 2.5rem; // 1.5rem container + 1rem column
      // }
    }
  }

  &.nmx-col-right-text-padding-left {
    @include breakpoint(medium) {
      padding-left: 2rem;
    }
  }

  &.nmx-col-left-text-padding-right {
    @include breakpoint(medium) {
      padding-right: 2rem;
    }
  }
}

@each $breakpoint, $breakpoint-size in $breakpoints {
  @include breakpoint($breakpoint-size) {
    $gutter: map-get($grid-gutters, $breakpoint);
    $outer-gutter: map-get($grid-outer-gutters, $breakpoint);
    $outer-gutter-val: $outer-gutter - $gutter;

    .nmx-col,
    .nmx-col-#{$breakpoint} {
      padding: 0 $gutter;
    }

    .nmx-col-#{$breakpoint} {
      @include col;

      &--collapse {
        flex: 0 1 auto;
      }
    }

    .nmx-container {
      padding: 0 $outer-gutter-val;
    }

    @for $i from 0 through $grid-column-count {
      $value: math.div(100% * $i, $grid-column-count);
      $display: block;
      @if $i == 0 {
        $display: none;
      }

      .nmx-col-#{$breakpoint}-#{$i} {
        @include col(0 $value, $value);
        display: $display;
      }

      .nmx-col-#{$breakpoint}--offset-#{$i} {
        margin-left: $value;
      }
    }
  }
}
