@import '../settings/settings';

.nmx {
  @each $heading in (h1, h2, h3, h4, h5, h6) {
    &-#{$heading}#{&}-#{$heading} {
      @include font-heading($heading);
    }
  }
}

.nmx-p {
  &#{&} {
    @include font-p();
  }
}

.nmx-small {
  font-size: (rem-calc($font-size-base * 0.8));
  line-height: rem-calc($font-line-height-base * 0.8);
}

.nmx-a {
  @include font-a();
}

// .nmx-a-small {
// TODO: finish this up, used for disclaimer links
// }

.nmx-a-ghost {
  @include font-a-ghost();
}

.nmx-link {
  &--stand-alone {
    // TODO: this would essentially be a nmx-button--tertiary
    &::after {
      display: inline-block;
      width: rem-calc(8px);
      height: rem-calc(11px);
      margin-top: rem-calc(6px);
      margin-left: rem-calc(5px);
      background: transparent
        url('/template/assets/<%=templateVersion%>/images/icons/utility/carat-right.svg')
        no-repeat 50% 50%;
      content: ' ';
      filter: invert(25%) sepia(96%) saturate(7900%) hue-rotate(195deg)
        brightness(100%) contrast(84%); // color to #1570bc ($color-system-cta-blue)
      @include breakpoint(small) {
        margin-top: rem-calc(7px);
      }
      @include breakpoint(large) {
        width: rem-calc(12px);
        height: rem-calc(12px);
        margin-top: rem-calc(8px);
      }
      @include breakpoint(xlarge) {
        width: rem-calc(15px);
        height: rem-calc(15px);
        margin-top: rem-calc(11px);
      }
    }
    &:hover,
    &:focus {
      text-decoration: underline;
      &::after {
        background-color: transparent;
        filter: invert(19%) sepia(89%) saturate(1128%) hue-rotate(183deg)
          brightness(46%) contrast(93%); // color to $color-system-cta-hover-blue
      }
    }
  }
}

.nmx-hero-h1 {
  @include hero-h1();
  color: $color-neutral-white;
  // @include text-shadow($offset: rem-calc(-10px), $blur: 3rem); // test in /home
  font-family: $font-family-sans-serif-hero;
  text-transform: uppercase;
  &:before {
    display: block;
    content: ' ';
    // @include hero-h1-shadow();
    // @include breakpoint(small) {
    //   @include hero-h1-shadow(medium);
    // }
    // @include breakpoint(medium) {
    //   @include hero-h1-shadow(large);
    // }
  }
}

.nmx-hero-h1-left {
  @include hero-h1-left();
  color: $color-neutral-white;
  // @include text-shadow($offset: 0, $blur: 4rem);
  font-family: $font-family-sans-serif-hero;
  text-transform: uppercase;
  &::before {
    z-index: -1;
    display: block;
    content: ' ';
    @include hero-h1-shadow(xsmall);
    // @include breakpoint(small) {
    //   @include hero-h1-shadow(medium);
    // }
    @include breakpoint(medium) {
      @include hero-h1-shadow(large);
    }
  }
}

.nmx-hero-h1-split {
  @include hero-h1-split();
  color: $color-neutral-white;
  font-family: $font-family-sans-serif-hero;
  text-shadow: none;
  text-transform: uppercase;
  &::before {
    z-index: -1;
    display: block;
    box-shadow: none;
    content: ' ';
  }
}

.nmx-hero-h1-center {
  @include hero-h1-center();
  color: $color-neutral-white;
  // @include breakpoint(small) {
  // @include text-shadow($offset: 0, $blur: 4rem);
  // }
  font-family: $font-family-sans-serif-hero;
  text-transform: uppercase;
  &::before {
    z-index: -1;
    display: block;
    content: ' ';
    @include hero-h1-shadow(xxsmall);
    // @include breakpoint(small) {
    //   @include hero-h1-shadow(xsmall);
    // }
    @include breakpoint(medium) {
      // @include hero-h1-shadow(large);
    }
  }
}

.nmx-hero-h1-right {
  @include hero-h1-right();
  color: $color-neutral-white;
  // @include breakpoint(small) {
  @include text-shadow($offset: 0, $blur: 4rem);
  // }
  font-family: $font-family-sans-serif-hero;
  text-transform: uppercase;
  &::before {
    z-index: -1;
    display: block;
    content: ' ';
    @include hero-h1-shadow(xsmall);
    // @include breakpoint(small) {
    //   @include hero-h1-shadow(small);
    // }
    @include breakpoint(medium) {
      @include hero-h1-shadow(large);
    }
  }
}

// .nmx-hero-p {
// &:before { // previously had a text-shadow here.
//   display: block;
//   content: ' ';
// }
// }

.nmx-jumbo-type {
  @include jumbo-type();
}
