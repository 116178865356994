// Add default font to base namespace
// (or body if namespace doesn't exist)
@include namespace($base-namespace or 'body') {
  color: $color-neutral-gray-29;
  font-family: $font-family-text-sans-serif;
}

@include namespace($base-namespace) {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  // Remove the clear button from text inputs in IE 10+
  ::-ms-clear {
    display: none;
  }

  embed,
  img,
  object,
  video {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-size: $font-size-base;
    font-weight: $weight-regular;
    letter-spacing: $font-letter-spacing;
    text-rendering: optimizeLegibility;
  }

  @each $heading in (h1, h2, h3, h4, h5, h6) {
    #{$heading} {
      @include font-heading($heading);
    }
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: $font-family-sans-serif;
  }

  p {
    margin: 0;
    color: $color-neutral-gray-29;
    font-family: $font-family-text-sans-serif;
    // duplicated in fonts mixin
    font-size: rem-calc(14px);
    font-style: normal;
    font-weight: $weight-light;
    letter-spacing: $font-letter-spacing;
    line-height: rem-calc(22px);
    text-rendering: optimizeLegibility;
    @include breakpoint(small) {
      font-size: rem-calc($font-size-base);
      line-height: rem-calc($font-line-height-base);
    }
    @include breakpoint(xlarge) {
      font-size: rem-calc(20px);
      line-height: rem-calc(30px);
    }
  }

  a {
    color: $color-system-cta-blue;
    // duplicated in fonts mixin
    // font-size: $font-size-base;
    font-weight: $weight-regular;
    letter-spacing: $font-letter-spacing;
    // line-height: $font-line-height-base;
    text-decoration: none;
    @include breakpoint(xsmall) {
      font-size: rem-calc(14px);
      line-height: rem-calc(22px);
    }
    @include breakpoint(small) {
      font-size: rem-calc($font-size-base);
      line-height: rem-calc($font-line-height-base);
    }

    @include breakpoint(xlarge) {
      font-size: rem-calc(20px);
      line-height: rem-calc(30px);
    }

    &:hover {
      color: $color-system-cta-hover-blue;
      text-decoration: underline;
    }

    &:active {
      color: $color-system-cta-active-blue;
    }

    &:focus {
      outline: auto 5px -webkit-focus-ring-color; // overwrite nmcom styles
    }
  }

  ul {
    padding-left: 1rem;
  }

  address {
    font-style: normal;
  }

  .nmx-assistive-text {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    margin: 0;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
  }

  @media print {
    @include namespace($base-namespace or 'body') {
      margin: 1cm;
    }

    * {
      color: $color-neutral-black !important;
      text-shadow: none !important;
    }

    embed,
    nav,
    object,
    video {
      display: none !important;
    }

    blockquote,
    img,
    pre {
      page-break-inside: avoid;
    }
  }
}
